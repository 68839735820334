.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  padding: 15px 20px;
  position: relative;
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.navbar:hover {
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar .nav-links {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-width: 452px;
  max-width: 40%;
  list-style-type: none;
  padding-inline-start: 0;
}

.navbar .nav-links a::after {
  content: "";
  height: 2px;
  width: 110%;
  background: black;
  position: absolute;
  left: -5%;
  bottom: -8px;
  opacity: 0;
  transition: all 0.2s;
  pointer-events: none;
}

.navbar .nav-links a:not(.active):hover::after {
  opacity: 1;
}

.navbar .nav-links a {
  text-decoration: none;
  color: black;
  font-family: CenturyGothic, AppleGothic, sans-serif;
  letter-spacing: 0.1rem;
  margin: 0 20px;
  position: relative;
}

.navbar .active {
  font-weight: bolder;
  pointer-events: none;
}

.navbar .logo {
  width: 230px;
  min-width: 230px;
  height: 60px;
  object-fit: cover;
  object-position: right;
  margin: -20px -30px -30px -20px;
}

.navbar .menu {
  display: none;
  position: absolute;
  top: 30px;
  right: 20px;
  flex-direction: column;
  justify-content: space-between;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.navbar .menu span {
  background-color: black;
}

.navbar .menu span.vertical {
  height: 25px;
  width: 1px;
  position: absolute;
  left: 12px;
}

.navbar .menu span.horizontal {
  height: 1px;
  width: 25px;
  position: absolute;
  bottom: 12px;
}

/******************************* RESPONSIVE ******************************/
@media (max-width: 800px) {
  .navbar .menu {
    display: flex;
  }

  .navbar {
    flex-direction: column;
    align-items: start;
  }

  .navbar .nav-links {
    display: flex;
    flex-direction: column;
    background-color: white;
    margin: 0;
    align-items: anchor-center;
    min-width: 0;
    max-width: none;
    position: absolute;
    top: 100%;
    right: -100%;
    width: 100%;
    transition: transform 0.7s ease-in;
    z-index: 100;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-top: 1px solid darkgray;
  }

  .navbar .nav-links.open {
    transform: translateX(-100%);
  }

  .navbar.open {
    max-height: 500px;
  }

  .navbar .menu span {
    transition: transform 0.5s;
  }

  .navbar .menu.open span {
    transform: rotate(45deg);
  }

  .navbar .nav-links li {
    width: 100%;
    margin: 30px;
    text-align: right;
    font-size: x-large;
  }

  .navbar .logo {
    margin: 0 -30px -10px -20px;
  }
}
