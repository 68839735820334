.page-title {
  letter-spacing: 0.2rem;
}

.contact-container {
  text-align: center;
  padding: 40px;
}

.contact-info {
  display: flex;
  justify-content: space-around;
  text-align: left;
  max-width: 900px;
  margin: 0 auto;
  padding: 20px 0;
}
.contact-info h3 {
  font-size: 1.2em;
  text-align: center;
}
@media (max-width: 800px) {
  .contact-info {
    flex-direction: column;
  }
  .contact-info h3 {
    text-align: left;
  }
}

.contact-info p {
  margin: 5px 0;
}

.map {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.ig-logo {
  height: 20px;
  width: 20px;
}

.loc-logo {
  height: 25px;
  width: 25px;
  margin-bottom: 5px;
}

.mail-logo {
  height: 25px;
  width: 25px;
}

.logo-text {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 5px;
  position: relative;
  min-height: 25px;
}
